import { useContext, useEffect } from "react";
import { ModalContext } from "context/modals";
import { SelectedItems } from "context/selected_items";
import ModalLayout from "layout/Modal";
import { useMutation } from "react-query";
import { handleErrors, fetcher } from "services/apiClient";
import { ButtonLoader } from "components/Loading";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import style from "./style.module.css";

const RegisterAccountModal = () => {
  const history = useNavigate();
  const { setRegisterAccountModal, registerAccountModal } =
    useContext(ModalContext);
  const { merchantID, bankResolutionDetails } = useContext(SelectedItems);

  const { isLoading, mutateAsync } = useMutation<any>(
    (formData) => fetcher("merchant-v1/withdrawal/register", formData),
    { retry: false }
  );

  useEffect(() => {
    console.log("bankResolutionDetails", bankResolutionDetails);
  }, [bankResolutionDetails]);

  const addAccount = async (data: any = {}) => {
    const merchant_id: string = merchantID;
    const account_name: string = bankResolutionDetails?.account_name;
    const bank_id: string = bankResolutionDetails?.bank_id;
    const account_number: string = bankResolutionDetails?.account_number;
    const bank_logo: string = bankResolutionDetails?.bank_logo;
    const bank_name: string = bankResolutionDetails?.bank_name;

    data = {
      merchant_id,
      account_name,
      bank_id,
      account_number,
      bank_name,
      bank_logo,
    };

    try {
      const res = await mutateAsync({ ...data });
      toast.success(res.message);
      setRegisterAccountModal(!registerAccountModal);
      window.location.reload();
    } catch (error) {
      handleErrors(error, history);
      setRegisterAccountModal(!registerAccountModal);
    }
  };

  return (
    <ModalLayout
      setIsOpen={setRegisterAccountModal}
      isOpen={registerAccountModal}
    >
      <h4 className="mid20 modal_header">Register Account Number</h4>
      <div className={style.list_wrapper}>
        <div className={style.list_item}>
          <span> Account Name: </span>
          <span>{bankResolutionDetails?.account_name}</span>
        </div>
        <div className={style.list_item}>
          <span> Account Number: </span>
          <span>{bankResolutionDetails?.account_number}</span>
        </div>
        <div className={style.list_item}>
          <span> Bank Details: </span>
          <div className={style.bank_detail_wrapper}>
            <div className={style.logo_wrapper}>
              <img alt="" src={bankResolutionDetails?.bank_logo} />
            </div>
            <span>{bankResolutionDetails?.bank_name}</span>
          </div>
        </div>
      </div>
      <button
        onClick={() => addAccount()}
        className="modal_btn modal_btn_main"
        type="submit"
      >
        {!isLoading ? "Register Account" : <ButtonLoader />}
      </button>
    </ModalLayout>
  );
};

export default RegisterAccountModal;
