import { createGlobalStyle } from 'styled-components';
import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    aellablue: '#2054D2',
    aellablack: '#052164',
    aellawhite: '#ffffff',
    aellablacktext: '#011C34',
    aellatextsecondary: '#4F4F4F',
    aellagrey: '#7B7B7B',
    activegreen: '#219653',
    aellabluesecondary: '#F2F6FF',
    aellabgblue: '#F6F8FD',
    aellasuccess: '#1FD96E',
    aellawarning: '#DE0C0C',
    aellaorange: '#F2994A',
    aellared: '#F21515',
    aellapink: '#eed3c5',
  },
};

export const GlobalStyle = createGlobalStyle`

    .user_details_wrapper{
        display: grid;
        grid-template-columns: 244px 1fr;
        column-gap: 24px;
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .pointer{
        cursor: pointer;
    }

    a,button {
        cursor: pointer;
    }

    .width50{
        width: 25%;
    }

    .width100{
        width: 100%;
    }

    
    button {
        border: none;
    }

    .pointer {
        cursor: pointer;
    }

    a, span{
        display: block;
        text-decoration: none;
    }

    .main_dash_wrapper {
        padding: 40px 40px 0 40px;
        position: relative;
    }

    .status {
        display: flex;
        width: fit-content;
        border-radius: 4px;
        height: 30px;
        padding: 6px;
        font-size: 14px;
        text-transform: capitalize;
    }   

    .success {
        background: rgba(33, 150, 83, 0.1);
        color: #219653;
    }

    .failed {
        background: rgba(242, 21, 21, 0.1);
        color: #f21515;
    }

    .pending {
        background: rgba(242, 153, 74, 0.1);
        color: ${props => props.theme.colors.aellaorange};
    }

    .more_details_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        white-space: nowrap;
    }

    .more_details_sub_title{
        width: 40%;
        color: ${props => props.theme.colors.aellagrey};
    }

    .more_detail{
        color: ${props => props.theme.colors.aellablacktext}; 
    }

    .detail_list_container{
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
    }

    .detail_row{
        display: inherit;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        background-color: #EDF2FF;
        margin-bottom: 12px;
        padding: 8px 24px;
        height: 40px;
    }

    .list_row_title{
        color: #4F4F4F
    }

    .list_row_details{
        color: #152952
    }

    .nowrap {
        white-space: nowrap;
    }

.manage_item_icon{
    width: 20px;
    height: 20px;
}

.loan_action_btn {
    width: 100%;
    background: ${props => props.theme.colors.aellawhite};
    height: 40px;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid ${props => props.theme.colors.aellablue};
    margin-bottom: 16px;
    color: ${props => props.theme.colors.aellablue};
    }

    .main_loan_btn {
        background: ${props => props.theme.colors.aellablue};
        color: ${props => props.theme.colors.aellawhite};
    }

    .input_grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap:  16px;
    }

.benefits_wrapper{
    background: ${props => props.theme.colors.aellawhite};
    height: 400px;
    overflow: auto;
    margin-top: 8px;
    box-shadow: 0px 3.69231px 4.61538px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
}

.benefits_description{
    margin-left: 16px;
    flex: 1;
}

.drawer_customer_details{
    margin-top: 32px;
    background: ${props => props.theme.colors.aellapink};
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 32px;
}

.benefits_check_wrapper {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: rgba(32, 84, 210, .15);
    display: flex;
    justify-content: center;
    align-items: center;
}

.benefits_check{
    width: 8.33px;
    height: 6.22px;
}

    .insurance_user_info{
        margin-top: 8px;
        padding: 16px;
        box-shadow: 0px 3.69231px 4.61538px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background-color:${props => props.theme.colors.aellawhite};
    }

    .insurance_info_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
    }

    @media only screen and (max-width: 600px) {
        .input_grid{
            grid-template-columns: 1fr;
        }

        .main_dash_wrapper {
    padding: 24px 16px 0 16px;
    position: relative;
  }
    }
`;
