import styled from "styled-components";

export const DefaultButtonStyle = styled.button<{ issecondary?: boolean }>`
  width: 100%;
  height: 100%;
  color: ${(props) =>
    props.issecondary
      ? `${props.theme.colors.aellablue}`
      : `${props.theme.colors.aellawhite}`};
  background-color: ${(props) =>
    props.issecondary ? "rgba(0,0,0,0)" : `${props.theme.colors.aellablue}`};
  font-size: 1rem;
  font-weight: 400;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.aellablue};
  margin-top: ${(props) => (props.issecondary ? "16px" : "0")};
`;

export const IconButtonStyle = styled(DefaultButtonStyle)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: ${(props) => props.theme.colors.aellatextsecondary};
  font-size: 0.875rem;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #edeff5;
  font-weight: 300;
  cursor: pointer;
  .filter {
    margin-right: 12px;
  }
`;
