import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/auth";
import { SelectedItems } from "context/selected_items";
import { ModalContext } from "context/modals";
import { HeaderStyle } from "./style";
import { LogoBlueBG, Hamburger } from "assets/icons/sidebar/index";
import { queryClient, handleErrors } from "services/apiClient";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const history = useNavigate();
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("_AELLA_TOKEN")) setHasToken(true);
  }, []);

  const { userProfile, setUserProfile, profileIsLoaded, setProfileIsLoaded } =
    useContext(AuthContext);

  const {
    merchantID,
    setMerchantID,
    setMerchantData,
    setKey,
    setWebhook,
    setCreateBankPrompt,
    setBankDetails,
    setTransactionMetrics,
    setCustomerMetrics,
    setAllBanks,
    setMerchantBankAccount,
  } = useContext(SelectedItems);

  const { mobileSidebar, setMobileSidebar } = useContext(ModalContext);

  const profile_url = "user/profile";
  const merchant_banks = `merchant-v1/withdrawal/${merchantID}`;
  const merchant_url = `merchant-v1/${userProfile.id}/${merchantID}`;
  const credential_url = `merchant-v1/credential/${merchantID}`;
  const webhook_url = `merchant-v1/webhook/${merchantID}`;
  const account_details_url = `merchant-v1/bank-account/${merchantID}`;
  const transaction_metrics_url = `merchant-v1/transaction/${merchantID}/metrics`;
  const customer_metrics_url = `merchant-v1/customer/${merchantID}/metrics`;
  const get_all_banks = `merchant-v1/withdrawal/banks`;

  useQuery([profile_url], queryClient, {
    retry: false,
    enabled: hasToken,
    onSuccess: (data) => {
      setUserProfile(data.data);
      setProfileIsLoaded(true);
    },
    onError: (data) => handleErrors(data, history),
  });

  useQuery([merchant_banks], queryClient, {
    retry: false,
    onSuccess: (data) => {
      setMerchantBankAccount(data.data);
    },
    onError: (data) => handleErrors(data, history),
  });

  useQuery([get_all_banks], queryClient, {
    retry: false,
    onSuccess: (data) => {
      setAllBanks(data.data);
    },
  });

  useQuery([merchant_url], queryClient, {
    enabled: profileIsLoaded,
    retry: false,
    onSuccess: (data) => {
      setMerchantData(data.data);
    },
    onError: (data) => handleErrors(data, history),
  });

  useQuery([credential_url], queryClient, {
    enabled: profileIsLoaded,
    retry: false,
    onSuccess: (data) => {
      if (data?.data === null) {
      } else {
        setKey(data?.data);
      }
    },
    onError: (data) => handleErrors(data, history),
  });

  useQuery([webhook_url], queryClient, {
    enabled: profileIsLoaded,
    retry: false,
    onSuccess: (data) => {
      if (data?.data === null) {
      } else {
        setWebhook(data?.data);
      }
    },
    onError: (data) => handleErrors(data, history),
  });

  useQuery([account_details_url], queryClient, {
    enabled: profileIsLoaded,
    retry: false,
    onSuccess: (data) => {
      if (data.status === "failed") {
      } else {
        setBankDetails(data?.data[0]);
        setCreateBankPrompt(false);
      }
    },
    onError: (data: any) => {
      if (data.response?.status === 400) {
        setCreateBankPrompt(true);
      } else {
        handleErrors(data, history);
      }
    },
  });

  useQuery([transaction_metrics_url], queryClient, {
    enabled: profileIsLoaded,
    retry: false,
    onSuccess: (data) => {
      setTransactionMetrics(data?.data);
    },
    onError: (data) => handleErrors(data, history),
  });

  useQuery([customer_metrics_url], queryClient, {
    enabled: profileIsLoaded,
    retry: false,
    onSuccess: (data) => {
      setCustomerMetrics(data?.data);
    },
    onError: (data) => handleErrors(data, history),
  });

  const sidebarToggler = () => {
    setMobileSidebar(!mobileSidebar);
  };

  useEffect(() => {
    if (userProfile.merchant_id !== null) {
      setMerchantID(userProfile.merchant_id);
    }
    // eslint-disable-next-line
  }, [userProfile]);

  return (
    <HeaderStyle>
      <div className="hamburger_outer">
        <div className="hamburger_wrapper">
          <Hamburger onClick={() => sidebarToggler()} />
        </div>
        <LogoBlueBG />
      </div>

      <div className="toggle_wrapper">BETA</div>
    </HeaderStyle>
  );
};
