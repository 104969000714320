import React from "react";
import { DefaultButtonStyle, IconButtonStyle } from "./Button.style";

type defaultButtonProp = {
  children: React.ReactNode;
  type?: string;
  disabled?: boolean;
  issecondary?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
export const ButtonDefault = ({
  children,
  disabled,
  issecondary,
  onClick,
  ...prop
}: defaultButtonProp) => {
  return (
    <DefaultButtonStyle
      onClick={onClick}
      issecondary={issecondary}
      disabled={disabled}
    >
      {children}
    </DefaultButtonStyle>
  );
};

export const IconButton = ({ children, ...prop }: defaultButtonProp) => {
  return <IconButtonStyle>{children}</IconButtonStyle>;
};
