import { useContext } from "react";
import { SidebarStyle } from "./style";
import { SelectedItems } from "context/selected_items";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isNotEmptyArray } from "utils";
import {
  Home,
  Wallet,
  Transactions,
  Settings,
  Customers,
} from "assets/icons/sidebar/index";
import { AuthContext } from "context/auth";

type sidebarProps = {
  sidebar_links: {
    title: string;
    url: string;
    img: string;
  }[];
};

export const Sidebar = ({ sidebar_links }: sidebarProps) => {
  const { setIsAuthed } = useContext(AuthContext);
  const { webhook, key } = useContext(SelectedItems);
  const location = useLocation();
  const history = useNavigate();
  let pathname = location.pathname.slice(1);

  const getSvgFill = (link: string) => {
    if (pathname.includes(link)) {
      return "#2054D2";
    } else {
      return "#A6A6A6";
    }
  };

  const getSvgComponents = (img: string, link: string) => {
    switch (img) {
      case "Home":
        return <Home color={`${getSvgFill(link)}`} />;

      case "Wallet":
        return <Wallet color={`${getSvgFill(link)}`} />;

      case "Transactions":
        return <Transactions color={`${getSvgFill(link)}`} />;

      case "Customers":
        return <Customers color={`${getSvgFill(link)}`} />;

      case "Settings":
        return <Settings color={`${getSvgFill(link)}`} />;
    }
  };

  const getActiveLink = (link: string) => {
    if (pathname.includes(link)) {
      return "active_link";
    }
  };

  const logout = () => {
    sessionStorage.clear();
    setIsAuthed(false);
    history("/login");
    window.location.reload();
  };

  const displayBadge = () => {
    if (!isNotEmptyArray(webhook) === true || !isNotEmptyArray(key) === true) {
      return true;
    }
    return false;
  };

  return (
    <SidebarStyle>
      <div>
        <div className="links_container">
          {sidebar_links.map((link, index) => (
            <Link
              className={`sidebar_link ${getActiveLink(link.url)}`}
              key={index}
              to={{
                pathname: `/${link.url}`,
              }}
            >
              {getSvgComponents(`${link.img}`, `${link.url}`)}
              <span className="link_title">{link.title}</span>
            </Link>
          ))}
          <Link
            className={`sidebar_link ${getActiveLink("settings")}`}
            to={{
              pathname: "/settings",
            }}
          >
            {getSvgComponents("Settings", "settings")}
            <span className="link_title badge_wrapper">
              Settings
              {displayBadge() && (
                <div className="badge badge_red reg12">New</div>
              )}
            </span>
          </Link>
        </div>
      </div>
      <button className="sidebar_link logout_btn" onClick={logout}>
        Logout
      </button>
    </SidebarStyle>
  );
};

Sidebar.defaultProps = {
  sidebar_links: [
    {
      title: "Dashboard",
      url: "dashboard",
      img: "Home",
    },
    {
      title: "Wallet",
      url: "wallet",
      img: "Wallet",
    },
    {
      title: "Transactions",
      url: "transactions",
      img: "Transactions",
    },
    {
      title: "Customers",
      url: "customers",
      img: "Customers",
    },
  ],
};
