import { useContext } from "react";
import { ModalContext } from "context/modals";
import { SelectedItems } from "context/selected_items";
import ModalLayout from "layout/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import { handleErrors, fetcher } from "services/apiClient";
import { ButtonLoader } from "components/Loading";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Input, SelectInput } from "components/Input/InputFields";

interface IFormInput {
  account_number: string;
  bank_id: "string";
}

const AddAccountModal = () => {
  const history = useNavigate();
  const {
    addAccountModal,
    setAddAccountModal,
    setRegisterAccountModal,
    registerAccountModal,
  } = useContext(ModalContext);
  const { allBanks, setBankResolutionDetails } = useContext(SelectedItems);

  const { isLoading, mutateAsync } = useMutation(
    (formData) => fetcher("merchant-v1/withdrawal/resolve", formData),
    { retry: false }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const addAccount: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      const res = await mutateAsync(data);
      toast.success(res.message);
      setBankResolutionDetails(res.data);
      setAddAccountModal(!addAccountModal);
      setRegisterAccountModal(!registerAccountModal);
    } catch (error) {
      handleErrors(error, history);
    }
  };

  return (
    <ModalLayout setIsOpen={setAddAccountModal} isOpen={addAccountModal}>
      <h4 className="mid20 modal_header">Add An Account Number</h4>
      <form className="width100" onSubmit={handleSubmit(addAccount)}>
        <SelectInput name="bank_id" register={register} label="Select an Bank">
          <option value="">Select a Bank</option>
          {allBanks?.map((value: any, index: number) => (
            <option key={index} value={value.id}>
              {value.name}
            </option>
          ))}
        </SelectInput>
        {errors.account_number && (
          <span className="reg12 form_error">Please check your address</span>
        )}
        <Input
          label=""
          placeholder="Enter your account number"
          type="text"
          {...register("account_number", {
            required: "Please provide a value",
          })}
        />

        <div className="width100">
          <button className="modal_btn modal_btn_main" type="submit">
            {!isLoading ? "Resolve Account" : <ButtonLoader />}
          </button>
        </div>
      </form>
    </ModalLayout>
  );
};

export default AddAccountModal;
