import { AuthLayout } from "layout/Auth/index";
import { LoginStyle } from "../Login/style";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import { fetcher, handleErrors } from "services/apiClient";
import { Input } from "components/Input/InputFields";
import { ButtonDefault } from "components/Input/Buttons";
import { ButtonLoader } from "components/Loading";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface IFormInput {
  email_or_phone_number: string | number;
  new_password: string;
  confirm_new_password: string;
  otp: string;
}

const ResetPassword = () => {
  const history = useNavigate();
  const { isLoading, mutateAsync } = useMutation(
    (data) => fetcher("user/reset-password/update", data),
    {
      retry: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    if (data.new_password !== data.confirm_new_password) {
      toast.error("Please make sure both passwords match");
    } else {
      try {
        const res = await mutateAsync(data);
        toast.success(res.message);
        history("/login");
      } catch (error) {
        handleErrors(error, history);
      }
    }
  };
  return (
    <AuthLayout>
      <LoginStyle>
        <form onSubmit={handleSubmit(onSubmit)} className="width100">
          {errors.otp && (
            <span className="reg12 form_error">Please check your OTP</span>
          )}
          <Input
            label=""
            placeholder="Enter OTP"
            {...register("otp", {
              required: "Please provide a value",
            })}
            type="text"
          />

          {errors.email_or_phone_number && (
            <span className="reg12 form_error">
              Please check your email or phone number
            </span>
          )}
          <Input
            label=""
            placeholder="Email address/password"
            {...register("email_or_phone_number", {
              required: "Please provide a value",
            })}
            type="text"
          />

          {errors.new_password && (
            <span className="reg12 form_error">
              Please check your email or phone number
            </span>
          )}
          <Input
            label=""
            placeholder="Password"
            type="password"
            {...register("new_password", {
              required: "Please provide a value",
            })}
          />

          {errors.confirm_new_password && (
            <span className="reg12 form_error">
              Please check your email or phone number
            </span>
          )}
          <Input
            label=""
            placeholder="Password"
            type="password"
            {...register("confirm_new_password", {
              required: "Please provide a value",
            })}
          />

          <div className="login_btn_wrapper">
            <ButtonDefault disabled={isLoading} type="submit">
              {isLoading ? <ButtonLoader /> : "Reset Password"}
            </ButtonDefault>
          </div>
        </form>
      </LoginStyle>
    </AuthLayout>
  );
};

export default ResetPassword;
