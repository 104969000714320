import "./styles/index.css";
import "./styles/mobileindex.css";
import "styles/style.scss";
import React, { useContext } from "react";
import { AuthContext } from "context/auth";
import { SelectedItemsProvider } from "context/selected_items";
import { ModalContextsProvider } from "context/modals";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "GlobalStyle/App.style";
import { QueryClient, QueryClientProvider } from "react-query";
import { Login, Main, Register } from "pages/index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "pages/protected.route";
import { CreateMerchant } from "components/DashMainHeader/register-merchant-modal";
import { DashLayout } from "layout/Dash/index";
import { Toaster } from "react-hot-toast";
import FallBackScreen from "components/FallBackScreen";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ForgotPassword/password-reset";
import AddAccountModal from "components/Modals/BankAccount/AddAccountModal";
import RegisterAccountModal from "components/Modals/BankAccount/RegisterAccountModal";
import WithdrawFromAccount from "components/Modals/BankAccount/WithdrawFromAccount";

const queryClient = new QueryClient();

function App() {
  const { isAuthed } = useContext(AuthContext);

  console.log(isAuthed);
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      />
      <BrowserRouter>
        <SelectedItemsProvider>
          <React.Suspense fallback={<FallBackScreen />}>
            <ModalContextsProvider>
              <ThemeProvider theme={theme}>
                <CreateMerchant />
                <AddAccountModal />
                <RegisterAccountModal />
                <WithdrawFromAccount />
                <GlobalStyle />

                <Routes>
                  <Route path="/register" element={<Register />} />

                  <Route path="/login" element={<Login />} />

                  <Route path="/forgot-password" element={<ForgotPassword />} />

                  <Route path="/reset-password" element={<ResetPassword />} />

                  <Route
                    path="*"
                    element={
                      isAuthed ? (
                        <DashLayout>
                          <Main />
                        </DashLayout>
                      ) : (
                        <Login />
                      )
                    }
                  />
                </Routes>
              </ThemeProvider>
            </ModalContextsProvider>
          </React.Suspense>
        </SelectedItemsProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
