import React from "react";
import { useContext } from "react";
import { SelectedItems } from "context/selected_items";
import { Routes, Route } from "react-router-dom";
import { DeveloperKeys, WebHooks, Profile } from "components/Settings";
import { NestedNav } from "components/NestedNav";
export const Login = React.lazy(() => import("./Login"));
export const Register = React.lazy(() => import("./Register"));
export const Home = React.lazy(() => import("./Home"));
export const Wallet = React.lazy(() => import("./Wallet"));
export const Transactions = React.lazy(() => import("./Transactions"));
export const Settings = React.lazy(() => import("./Settings"));
export const Customers = React.lazy(() => import("./Customers"));
export const NotFound = React.lazy(() => import("./NotFound"));

export const Main = () => {
  const { merchantData } = useContext(SelectedItems);
  return (
    <main>
      <Routes>
        <Route path="/dashboard" element={<Home />} />

        <Route path="/wallet" element={<Wallet />} />

        <Route path="/transactions" element={<Transactions />} />

        <Route path="/customers" element={<Customers />} />

        <Route path="/settings" element={<Settings />} />

        <Route
          path="/settings/profile"
          element={
            <div className="main_dash_wrapper nested_body">
              <NestedNav>
                <Profile
                  address={merchantData?.address}
                  name={merchantData?.name}
                  email={merchantData?.email}
                  phone_number={merchantData?.phone_number}
                />
              </NestedNav>
            </div>
          }
        />

        <Route
          path="/settings/developer-keys"
          element={
            <div className="main_dash_wrapper nested_body">
              <NestedNav>
                <DeveloperKeys />
              </NestedNav>
            </div>
          }
        />

        <Route
          path="/settings/webhooks"
          element={
            <div className="main_dash_wrapper nested_body">
              <NestedNav>
                <WebHooks />
              </NestedNav>
            </div>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </main>
  );
};
