import { useContext } from "react";
import { SelectedItems } from "context/selected_items";
import { NavLink, useLocation } from "react-router-dom";
import { NestedNavStyle } from "./styles";
import { DashHeader } from "components/DashMainHeader/index";
import { isNotEmptyArray } from "utils";

export const NestedNav = ({ children }: any) => {
  const location = useLocation();
  const { webhook, key } = useContext(SelectedItems);

  const getActiveLink = (link: string) => {
    if (location.pathname.includes(link)) {
      return "active_link";
    }
  };

  return (
    <NestedNavStyle className="">
      <DashHeader />
      <div className="nav_wrapper">
        <div className="tab_list">
          <span className="tab_item_wrapper">
            <NavLink
              className={`tab_item ${
                getActiveLink("/settings/profile") ? "selected" : ""
              }`}
              to={`/settings/profile`}
            >
              Profile
            </NavLink>
          </span>

          <span className="tab_item_wrapper">
            <NavLink
              className={`tab_item ${
                getActiveLink("/settings/webhooks") ? "selected" : ""
              }`}
              to={`/settings/webhooks`}
            >
              Webhook
            </NavLink>
            {!isNotEmptyArray(webhook) && (
              <div className="badge badge_red reg12">New</div>
            )}
          </span>

          <span className="tab_item_wrapper">
            <NavLink
              className={`tab_item ${
                getActiveLink("/settings/developer-keys") ? "selected" : ""
              }`}
              to={`/settings/developer-keys`}
            >
              Developer Keys
            </NavLink>
            {!isNotEmptyArray(key) && (
              <div className="badge badge_red reg12">New</div>
            )}
          </span>
        </div>
        <hr className="divider" />
        <div>{children}</div>
      </div>
    </NestedNavStyle>
  );
};
