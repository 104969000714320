import { useContext } from "react";
import { DashBoardStyle } from "./style";
import { Sidebar } from "components/Sidebar";
import { Header } from "components/Header";
import { AuthContext } from "context/auth";
import { MobileSidebar } from "components/Sidebar/mobile_sidebar";

type dashProps = {
  children: JSX.Element;
};

export const DashLayout = ({ children }: dashProps) => {
  const { profileIsLoaded } = useContext(AuthContext);

  return (
    <DashBoardStyle>
      <MobileSidebar />
      <Header />
      {!profileIsLoaded ? (
        <div className="loader_bg">
          <div>
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Sidebar />
          <main className="main_dash_container">{children}</main>
        </>
      )}
    </DashBoardStyle>
  );
};
