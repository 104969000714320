import {
  IconInputStyle,
  TextAreaStyle,
  SelectStyle,
  SearchInputStyle,
  SelectFileStyle,
} from "./style";
import { ReactComponent as SearchIcon } from "assets/icons/main/u_search.svg";
import { iconInputProps, search, text_area } from "./type";
import React from "react";

export const Input: React.FC<iconInputProps> = React.forwardRef(
  (
    {
      label,
      placeholder,
      name,
      type,
      register,
      isSearch,
      errors,
      maxLength,
      minLength,
      errorMessage,
      isModal,
      regex,
      ...rest
    },
    ref: any
  ) => {
    return (
      <IconInputStyle is_modal={isModal} is_search={isSearch}>
        {label === "" ? null : (
          <label className="input_label" htmlFor={name}>
            {label}
          </label>
        )}
        <div className="icon_input_container">
          <input
            className="input"
            name={name}
            placeholder={placeholder}
            id={name}
            ref={ref}
            type={type}
            {...rest}
          />
          {errors && errors.name && ""}
        </div>
      </IconInputStyle>
    );
  }
);

export const SearchInput: React.FC<search> = React.forwardRef(
  ({ name, placeholder, onChange, value }, ref: any) => {
    return (
      <SearchInputStyle>
        <div className="icon_input_container">
          <div className="input_icon">
            <SearchIcon />
          </div>
          <input
            onChange={onChange}
            className="input"
            type="text"
            id={name}
            name={name}
            ref={ref}
            value={value}
            placeholder={placeholder}
          />
        </div>
      </SearchInputStyle>
    );
  }
);

export const SelectFile: React.FC<any> = React.forwardRef(
  ({ label, name, placeholder, register, ...rest }, ref: any) => {
    return (
      <SelectFileStyle>
        <>
          <label className="input_label" htmlFor={name}>
            {label}
          </label>
          <div className="icon_input_container">
            <input
              className="input input_file"
              {...register(name, { required: true })}
              name={name}
              placeholder={placeholder}
              id={name}
              ref={ref}
              type="file"
              {...rest}
            />
          </div>
        </>
      </SelectFileStyle>
    );
  }
);

export const TextArea: React.FC<text_area> = React.forwardRef(
  ({ label, placeholder, name, isModal, ...rest }, ref: any) => {
    return (
      <TextAreaStyle is_modal={isModal}>
        <label className="reg12" htmlFor={name}>
          {label}
        </label>
        <textarea
          placeholder={placeholder}
          name={name}
          ref={ref}
          id={name}
          {...rest}
        ></textarea>
      </TextAreaStyle>
    );
  }
);

export const SelectInput: React.FC<any> = React.forwardRef(
  ({ children, label, name, register, options }, ref: any) => {
    return (
      <SelectStyle>
        <label className="reg12" htmlFor={name}>
          {label}
        </label>
        <select ref={ref} {...register(name)}>
          {children}
        </select>
      </SelectStyle>
    );
  }
);
