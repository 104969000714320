import { useContext } from "react";
import { ModalContext } from "context/modals";
import { AuthContext } from "context/auth";
import ModalLayout from "layout/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import { handleErrors, fetcher } from "services/apiClient";
import { ButtonLoader } from "components/Loading";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Input, TextArea } from "components/Input/InputFields";

interface IFormInput {
  fraud_type_id: string;
  password: string;
  name: string;
  email: string;
  phone_number: string;
  address: string;
  description: string;
}

export const CreateMerchant = () => {
  const history = useNavigate();
  const { createMerchantModal, setCreateMerchantModal } =
    useContext(ModalContext);
  const { userIsMerchant, setUserIsMerchant } = useContext(AuthContext);

  const { isLoading, mutateAsync } = useMutation(
    (formData) => fetcher("merchant", formData),
    { retry: false }
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const addTeamMember: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      const res = await mutateAsync(data);
      setUserIsMerchant(!userIsMerchant);
      setCreateMerchantModal(!createMerchantModal);
      toast.success(res.message);
      window.location.reload();
    } catch (error) {
      handleErrors(error, history);
    }
  };

  return (
    <ModalLayout isOpen={createMerchantModal}>
      <h4 className="mid20 modal_header">Add A New Merchant</h4>
      <form className="width100" onSubmit={handleSubmit(addTeamMember)}>
        {errors.name && (
          <span className="reg12 form_error">Please check business name</span>
        )}
        <Input
          label=""
          placeholder="Enter your business name"
          {...register("name", {
            required: "Please provide a value",
          })}
          type="text"
        />

        {errors.email && (
          <span className="reg12 form_error">Please check your email</span>
        )}
        <Input
          label=""
          placeholder="Enter your email address"
          {...register("email", {
            required: "Please provide a value",
          })}
          type="email"
        />

        {errors.phone_number && (
          <span className="reg12 form_error">
            Please check phone number - 07012345678
          </span>
        )}
        <Input
          label=""
          placeholder="Enter your phone number"
          register={register}
          type="tel"
          {...register("phone_number", {
            required: "Please provide a value",
            minLength: 11,
            maxLength: 11,
          })}
        />

        {errors.address && (
          <span className="reg12 form_error">Please check your address</span>
        )}
        <Input
          label=""
          placeholder="Enter your physical address"
          type="text"
          {...register("address", {
            required: "Please provide a value",
          })}
        />

        {errors.description && (
          <span className="reg12 form_error">
            Please check business desctiption
          </span>
        )}
        <TextArea
          label=""
          placeholder="Describe what your business does"
          isModal={true}
          {...register("description", {
            required: "Please provide a value",
          })}
        />

        <div className="width100">
          <button className="modal_btn modal_btn_main" type="submit">
            {!isLoading ? "Onboard Merchant" : <ButtonLoader />}
          </button>
        </div>
      </form>
    </ModalLayout>
  );
};
