import { createContext, useState } from 'react';

export const SelectedItems = createContext();

export const SelectedItemsProvider = ({ children }) => {
  const [merchantID, setMerchantID] = useState(null);
  const [credential, setCredential] = useState(null);
  const [merchantData, setMerchantData] = useState({});
  const [selectedCustomerWallet, setSelectedCustomerWallet] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [key, setKey] = useState([]);
  const [webhook, setWebhook] = useState([]);
  const [bankDetails, setBankDetails] = useState(null);
  const [createBankPrompt, setCreateBankPrompt] = useState(true);
  const [selectedWalletTransaction, setSelectedWalletTransaction] =
    useState(null);
  const [transactionMetrics, setTransactionMetrics] = useState({});
  const [customerMetrics, setCustomerMetrics] = useState({});
  const [allBanks, setAllBanks] = useState([]);
  const [bankResolutionDetails, setBankResolutionDetails] = useState({});
  const [merchantBankAccount, setMerchantBankAccount] = useState([]);

  return (
    <SelectedItems.Provider
      value={{
        merchantData,
        setMerchantData,

        merchantID,
        setMerchantID,

        selectedCustomerWallet,
        setSelectedCustomerWallet,

        searchQuery,
        setSearchQuery,

        selectedCustomer,
        setSelectedCustomer,

        selectedTransaction,
        setSelectedTransaction,

        credential,
        setCredential,

        key,
        setKey,

        webhook,
        setWebhook,

        createBankPrompt,
        setCreateBankPrompt,

        bankDetails,
        setBankDetails,

        selectedWalletTransaction,
        setSelectedWalletTransaction,

        transactionMetrics,
        setTransactionMetrics,

        customerMetrics,
        setCustomerMetrics,

        allBanks,
        setAllBanks,

        bankResolutionDetails,
        setBankResolutionDetails,

        merchantBankAccount,
        setMerchantBankAccount,
      }}>
      {children}
    </SelectedItems.Provider>
  );
};
